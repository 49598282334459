<template>
  <div class="product">
    <ShopHeader :designName="newlocale === 'en' ? shopInfo.shop_name_en : shopInfo.shop_name" :shopData="shopInfo" :showClass="true" :showPhoto="false" :showInfoTitle="false"/>
    <div class="wrapper-1590 mt-40">
      <RecommendSwiper />
    </div>
    <ProductList />
  </div>
</template>

<script>
import RecommendSwiper from '@/components/RecommendSwiper/index'
import ProductList from '@/components/ProductList/index'
import ShopHeader from './components/ShopHeader.vue'
import { post } from '@/utils/request'

export default {
  name: 'product',
  components: {
    ShopHeader,
    RecommendSwiper,
    ProductList
  },
  data() {
    return {
      id: '',
      shopInfo: {}
    }
  },
  computed: {
    newlocale() {
      return this.$i18n.locale
    }
  },
  created() {
    this.id = this.$router.currentRoute.query.id
    this.getShopInfo()
  },
  methods: {
    getShopInfo() {
      let params = {
        guid: this.id,
        callParams: 'klyx'
      }
      post('/klyx-server/shop/queryShopByGuid', { params: params }, true).then(
        (res) => {
          console.log('res: ', res.data)
          this.shopInfo = res.data
        }
      )
    }
  }
}
</script>

<style scoped lang="less">
// .product {
// }
.wrapper-1590 {
  max-width: 1590px;
  width: 100%;
  margin: 0 auto;
}
.mt-40 {
  margin-top: 40px;
}

.gift-content {
  display: flex;
}

.gift-content__pro {
  flex: 1;
}
.gift-pro__boxblank {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 1262px;
  height: 663px;
}
.isblankimg {
  width: 300px;
  height: 292px;
  opacity: 1;
}
.isblanktest {
  width: auto;
  height: 24px;
  font-size: 24px;
  font-family: Montserrat-Bold;
  font-weight: 600;
  line-height: 24px;
  margin-top: 35px;
  color: #1baf92;
  text-transform: uppercase;
  opacity: 1;
}
.gift-pro__pag {
  text-align: center;
  margin: 50px 0 70px;
}
.gift-pro__box {
  display: grid;
  grid-template-columns: repeat(4, auto);
}
</style>

<style lang="less">
@import '/src/assets/less/variables.less';

.gift-content {
  .el-pagination {
    .btn-prev {
      position: relative;
      border: none;
      background-color: transparent;

      i {
        display: none;
      }

      &:after {
        position: absolute;
        top: 0;
        left: -100%;
        // content: 'Previous';
        content: '上一页';
        line-height: 36px;
        font-size: 16px;
        font-weight: 500;

        color: @brand;
      }
    }

    .el-pager {
      .number {
        margin: 0 8px;
        width: 33px;
        height: 36px;
        border: 1px solid #97cbc1;
        border-radius: 8px;
        color: #97cbc1;

        line-height: 36px;
        font-size: 14px;

        &.active {
          color: #fff;
          background-color: @brand;
        }
      }

      .el-icon.more {
        height: 36px;
        color: #97cbc1;
        line-height: 36px;
      }
    }

    .btn-next {
      position: relative;
      border: none;
      background-color: transparent;

      i {
        display: none;
      }

      &:after {
        position: absolute;
        top: 0;
        left: 17px;
        // content: 'Next';
        content: '下一页';
        line-height: 36px;
        font-weight: 500;
        font-size: 16px;
        color: @brand;
      }
    }

    .btn-prev[disabled],
    btn-next[disabled] {
      opacity: 0.5;
    }
  }

  /*.el-pagination.is-background .el-pager li:not(.disabled).active {*/
  /*  background-color: #1baf92;*/
  /*}*/

  /*.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {*/
  /*  border-radius: 8px;*/
  /*  width: 33px;*/
  /*  height: 36px;*/
  /*  text-align: center;*/
  /*  line-height: 36px;*/
  /*  border: solid 1px #97cbc1;*/
  /*  background-color: #fff;*/
  /*  color: #1baf92;*/
  /*  */
  /*}*/
}
</style>
