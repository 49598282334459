var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product" },
    [
      _c("ShopHeader", {
        attrs: {
          designName:
            _vm.newlocale === "en"
              ? _vm.shopInfo.shop_name_en
              : _vm.shopInfo.shop_name,
          shopData: _vm.shopInfo,
          showClass: true,
          showPhoto: false,
          showInfoTitle: false,
        },
      }),
      _c(
        "div",
        { staticClass: "wrapper-1590 mt-40" },
        [_c("RecommendSwiper")],
        1
      ),
      _c("ProductList"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }